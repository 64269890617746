export default {
  data: function data() {
    return {
      list: [{
        icon: require("@/assets/fireproof/b/icon1.png"),
        t: "管理信息化",
        c: "取代人工纸质登记管理模式，实现器材管理可视化，数据实时化。"
      }, {
        icon: require("@/assets/fireproof/b/icon2.png"),
        t: "出入库自动登记",
        c: "实现器材领用、归还等出入自动登记管理，提高器材库存准确性。"
      }, {
        icon: require("@/assets/fireproof/b/icon3.png"),
        t: "车辆维护保养更高效",
        c: "每台车辆随车器材清点更快速，降低工作强度。"
      }, {
        icon: require("@/assets/fireproof/b/icon4.png"),
        t: "装备器材库存清点更快捷",
        c: "加速清点库存，及时掌握库存情况。"
      }]
    };
  }
};